import React from 'react'
import { useState, useEffect } from "react";
import Layout from '../components/layouts'
// import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';

function OSHAform() {

    useEffect(() => {
        
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);
        
        script.addEventListener('load', () => {
            if(window.hbspt) {
              window.hbspt.forms.create({
                portalId: '2339207',
              formId: '8491b96d-9507-4150-b327-f2486ebe5d56',
              target: '#oshaForm'
            })
          }
        });

      });

    // const [firstName, setFirstname] = useState("");
    // const [lastName, setLastname] = useState("");
    // const [email, setEmail] = useState("");

    // const handleSubmit = (event) => {

    //     event.preventDefault();

    //     console.log(firstName, lastName, email)

    //     var xhr = new XMLHttpRequest();
    //     var url = "https://api.hsforms.com/submissions/v3/integration/submit/2339207/8491b96d-9507-4150-b327-f2486ebe5d56";
    //     // In var url above, replace portalId and formId with your values from HubSpot.
    //     // Example request JSON:
    //     var data = {
    //         submittedAt: Date.now(),
    //         fields: [
    //             {
    //                 name: "email",
    //                 value: email,
    //             },
    //         ],
    //         context: {
    //             pageUri: "www.workhub.com/resources/landing/osha",
    //             pageName: "Osha Landing",
    //         },
    //     };
    //     var final_data = JSON.stringify(data);
    //     xhr.open("POST", url);
    //     // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    //     xhr.setRequestHeader("Content-Type", "application/json");
    //     xhr.onreadystatechange = function () {
    //         if (xhr.readyState == 4 && xhr.status == 200) {
    //             alert(xhr.responseText); // Returns a 200 response if the submission is successful.
    //         } else if (xhr.readyState == 4 && xhr.status == 400) {
    //             alert(xhr.responseText); // Returns a 400 error the submission is rejected.
    //         } else if (xhr.readyState == 4 && xhr.status == 403) {
    //             alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
    //         } else if (xhr.readyState == 4 && xhr.status == 404) {
    //             alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
    //         }
    //     };
    //     // Sends the request
    //     xhr.send(final_data);

    //     // const params = new URLSearchParams({
    //     //     email: email,
    //     //   });

    //     // window.location.href = "https://workhub.com/signup/" + "?" + params
        
    //     //  window.location.href = "https://workhub.com/"
    // };


    return (


                        // <form method="POST" action="/subscribe-success/" onSubmit={handleSubmit} id="osha-landing" className="landing-form osha-form" style={{maxWidth: '800px'}}>
                        //     <label for="lname osha-email" style={{ display: 'block', margin: '0 1rem 0 0' }}>Email:</label>
                        //     <input
                        //         type="email"
                        //         id="email"
                        //         name="email"
                        //         value={email}
                        //         onChange={e => setEmail(e.target.value)}
                        //         style={{ display: 'block', background: 'white', width: '100%'}}
                        //     />
                            
                        //     <input
                        //     className="agency_banner_btn  btn_hover osha-button"
                        //     style={{width: '150px'}}
                        //         type="submit"
                        //         id="submit"
                        //         name="submit"
                        //     />
                        // </form>

                        <>
                        <div id="oshaForm"></div>
                        </>
    )
}

export default OSHAform


	// portalId: "2339207",
	// formId: "8491b96d-9507-4150-b327-f2486ebe5d56"