import React from "react"
import Banner from "../../../../components/common/Banner"
import Layout from "../../../../components/layouts"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import PartnerGrid from "../../../../components/resources/partners/PartnerGrid"
import OSHAform from "../../../OSHAform"
import BecomePartnerForm from '../../../BecomePartnerform'
import { Link } from "gatsby"
import ReactWOW from "react-wow"
import SEO from '../../../../components/common/SEO'

export default () => {

  const title = () => (
    <div>
      <span>Become a <br /></span>Workhub<span> Partner</span>
    </div>
  )
  const bannerProps = {
    title: title,
    subtitle: "Partners",
    bannerImage: "/partners/partner-header.jpg",
    // buttonLeft: {
    //   text: "Become A Partner",
    //   link: "/about/careers"
    // },
    // buttonRight: {
    //   text: "What is a Partner",
    //   link: "/about/story"
    // }
  }

  const seo = {
    title: "Workhub | Become a Partner | Free EHS Management Software",
    description:
      "Workhub partners with industry experts & safety consultants to accelerate the implementation of our EHS software. Get profiled on our Partner page, benefit from ease-of-use and get rewarded.",
  }

  return (
    <Layout>
         <SEO {...seo}/>
       <Banner {...bannerProps}/>

    <div className="container become_partner_contain" style={{ display: "flex", justifyContent: 'space-around',  flexWrap: 'wrap', marginTop: '7vh', marginBottom: '2vh', maxWidth: '1200px' }}>

<aside style={{ maxWidth: '575px' }}>

   <h2 style={{marginBottom: '1rem'}}> What is a Partner? </h2>

  <p style={{marginBottom: '1rem'}}>Workhub creates relationships with industry experts and safety consultants in order to accelerate the implementation of our software within clients’ organizations. Using their expertise, partners act as consultants to new clients and ensure optimal use of the software within organizations.  </p>

  <p><strong>Would you like to request more information? Drop us a line.</strong> <img style={{height: '20px', width: '20px', marginLeft: '5px', marginBottom: '3px'}} alt="Down arrow" src="/landing/pencil.png" /></p>

  {/* <OSHAform/> */}

  <BecomePartnerForm/>

</aside>

<aside style={{ maxWidth: '575px' }}>

  <img className="partner-header-image partner_mrg_btm" style={{ width: '100%', margin: 'auto' }} src="/partners/become_banner.png" alt="Partner with Workhub icons surrounding him."/>


</aside>

</div>

<section className="pricing_testimonial" style={{maxWidth: '1200px'}}>
        <blockquote style={{marginTop: '0.5rem'}}>
          <div className="stars">
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
              <img src="/app-icons/partner-logos/star.png"/>
            </div>
          <p>
          "Workhub has been an <span className="span_gradient" style={{padding: '0px'}}>excellent</span> organization with which to partner, in the delivery of our safety system development and management services to our clients. We could not recommend them more highly."
          </p>
       
          <p className="partner_name" id="partner_name" style={{fontSize: '16px!important'}}><Link to="/resources/partners/solstice">Solstice Safety</Link></p>

          {/* <img className="testimonial_img" src="/app-icons/partner-logos/infinity.jpg"/> */}
        </blockquote>
      </section>


<div className="container partner-container" style={{ paddingBottom: '80px' }}>
      <h2 style={{ marginBottom: '2.5rem' }}>How can you benefit?</h2>
  <div className="partner-grid">

        <div className="partner-cards"><img src="/partners/star.png" alt="star"/>
          <h5 style={{ marginTop: '1rem' }}>Get Profiled on our <Link to="/resources/partners/find-a-partner/">Find a Partner</Link> Page  </h5>
          <p>Gain exposure through our partner profile pages, let our customers know who you are and what you can do for them. Add your logo, link and your own customer reviews!   </p>
          </div>

          <div className="partner-cards"><img src="/partners/thumb.png" alt="thumbs up"/>
          <h5 style={{ marginTop: '1rem' }}>Easy Use and Set-up  </h5>
          <p>The Workhub app is intuitive which means easy set-up, implementation, and support for you and your new clients. Create your own templates only once and re-use them for all your clients.   </p>
        </div>


        <div className="partner-cards"><img src="/partners/gift.png" alt="present"/>
          <h5 style={{ marginTop: '1rem' }}>Rewards</h5>
          <p>Hard work will never go unnoticed! As your client list grows, so does your status within Workhub, elevating your exposure further and rewarding you with a steady stream of new business. </p>
      </div>
        {/* Having problems? Your Partners are here for you.  */}


       
        </div>
    </div>
  </Layout>
  )
}
