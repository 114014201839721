import React from 'react'
import { Link } from 'gatsby'
import { Parallax } from 'react-parallax'
import ReactWOW from 'react-wow';

const Banner = (props) => {
    return(
        <section>
            <Parallax
                className="general_banner_area"
                    bgImage={(props.bannerImage !== undefined) ? props.bannerImage : '/about/our-office.jpg'}
                    bgImageAlt="our-office"
                    bgClassName="parallax-effect"
                    strength={600}>
                    <div className="container">
                        <div className="general_banner_content">
                            <ReactWOW animation="zoomIn" delay="0.2s"><div className="round"></div></ReactWOW>
                            <ReactWOW animation="fadeInUp" delay="0.6s"><h6>{props.subtitle}</h6></ReactWOW>
                            <ReactWOW animation="fadeInUp" delay="0.8s"><h2>{props.title()}</h2></ReactWOW>
                            {props.buttonLeft ? (<ReactWOW animation="fadeInLeft" delay="0.9s"><Link className="general_banner_btn general_banner_btn_two btn_hover" style={{marginRight: '5px'}} to={props.buttonLeft.link}>{props.buttonLeft.text}</Link></ReactWOW>) : null}
                           {props.buttonRight ? (<ReactWOW animation="fadeInRight" delay="0.9s"><Link className="general_banner_btn general_banner_btn_two btn_hover" to={props.buttonRight.link}>{props.buttonRight.text}</Link></ReactWOW>): null} 
                        </div>
                    </div>
            </Parallax>
        </section>
    )
}

export default Banner

