import React, {useState} from 'react'
import { Link, graphql } from 'gatsby'
import ReactWOW from 'react-wow';


// export const query = graphql`
// query MyQuery {
//   prismic {
//     allPartners {
//       edges {
//         node {
//           contact_info {
//             city
//             phone_number
//             search_terms
//           }
//           partner_level
//           logo
//           name
//           _meta {
//             uid
//           }
//           completed_projects
//         }
//       }
//     }
//   }
// }
// `

export default ({ data }) => {

  const [search, setSearch] = useState('')

  // const [searchQuery, setSearchQuery] = useState('')


  let arrs = [];
  if (data.prismic.allPartners != null) {

    data.prismic.allPartners.edges.forEach(partner => {
          arrs.push(partner)
          // console.log(partner.node)
    });
  }

      const gold = arrs.filter(word => word.node.partner_level === "Gold");

      const silver = arrs.filter(word => word.node.partner_level === "Silver");

      const bronze = arrs.filter(word => word.node.partner_level === "Bronze");

      let arr3 = [...gold, ...silver, ...bronze]


      const filteredOptions = arr3.filter(
        partner =>
          partner.node.contact_info[0].search_terms.toLowerCase().includes(search.toLowerCase()) ||
          !search
      );


  return (
    <section className="safety_course_area">
      <div className="container">
        <div className="hosting_title safety_title text-center">
          <ReactWOW animation="fadeInUp"><h2 ><span>Find a Partner</span></h2></ReactWOW>
          <p>Workhub has relationships with various safety consultants which can accelerate the implementation of a safety management system throughout your organization. Qualified consultants will maximize the value of your investment in the safety management system, lower your implementation costs and risks, and ensure optimal performance going forward.</p>
          <p>
            
            <div className="search-container" style={{position: 'relative', maxWidth: '400px', margin: 'auto'}}>

            <input
            className="partner-search"
              onKeyUp={(e) => {
                setSearch(e.target.value)
                // console.log(search)
              }}
              style={{ border: '1px solid #333', borderRadius: '0.5rem', width: '400px', padding: '5px' }} type="text"
              placeholder="Search province/state or country"
               />

              <img alt="search icon" style={{height: '25px', width: '25px', position: 'absolute', right: '5px', top: '7.5px'}}src="/partners/search.png" />
</div>

          </p>
        </div>
        <div className="row">

          {/* {arr3.filter((value)=> {
            if (search === '') {
            return value
            } if (value.node.contact_info[0].search_terms.toLowerCase().includes(search.toLowerCase()) === undefined) {
              return (<div>Sorry nothing for you</div>)
            }
            else if (value.node.contact_info[0].search_terms.toLowerCase().includes(search.toLowerCase())) {return value}
          }).map((partner, i) => {
            return (
            <div key={partner.node._meta.uid} className="col-lg-3 col-md-4 col-sm-6">
            <Link to={`/resources/partners/${partner.node._meta.uid}`} className="partner-list-item">
              <div className={`tag ${partner.node.partner_level.toLowerCase()}`}>
              <div>
                <span>{partner.node.partner_level}</span>
              </div>
              </div>
              <div className="partner-logo-container">
                <img src={(partner.node.logo) ? partner.node.logo.url : ''} alt="" />
              </div>
              <h5>{partner.node.name[0].text}</h5>


              <hr />
              <div className="partner-info">
                <div className="partner-info-location"><i className="ti-location-pin s_color"></i> {partner.node.contact_info[0].city}, {partner.node.contact_info[0].province}</div>
              </div>


            </Link>
          </div>
          )})}  */}


{filteredOptions.length > 0 ? (
            filteredOptions.map((partner, index) => {
              return (<>
              <div key={partner.node._meta.uid} className="col-lg-3 col-md-4 col-sm-6">
            <Link to={`/resources/partners/${partner.node._meta.uid}`} className="partner-list-item">
              <div className={`tag ${partner.node.partner_level.toLowerCase()}`}>
              <div>
                <span>{partner.node.partner_level}</span>
              </div>
              </div>
              <div className="partner-logo-container">
                <img src={(partner.node.logo) ? partner.node.logo.url : ''} alt={partner.node.name[0].text + ' Logo'} />
              </div>
              <h5>{partner.node.name[0].text}</h5>


              {/* <hr />
              <div className="partner-info">
                <div className="partner-info-location"><i className="ti-location-pin s_color"></i> {partner.node.contact_info[0].city}, {partner.node.contact_info[0].province}</div>
              </div> */}


            </Link>
          </div></>)
            })
          ) : (
            <div style={{margin: 'auto'}}>
              <p style={{textAlign:'center'}}>Oops, no results found for <strong>{search}</strong></p>  
              <p style={{textAlign:'center'}}>Contact <a href='mailto:sales@workhub.com'>sales@workhub.com</a> to find help in your area.</p>
            </div>
          )}


        </div>
      </div>
    </section>
  )
}
