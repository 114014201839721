import React, { useState, useEffect } from "react";

function BecomePartnerform() {

    useEffect(() => {
        
        const script = document.createElement('script');
        script.src = 'https://js.hsforms.net/forms/v2.js';
        document.body.appendChild(script);
        
        script.addEventListener('load', () => {
            if(window.hbspt) {
              window.hbspt.forms.create({
                portalId: '2339207',
              formId: 'b67f0c6a-e6e6-4429-8a19-cdc15a2b336f',
              target: '#becomePartner'
            })
          }
        });

      });

    return (
        <>
        <div id="becomePartner"></div>
        </>
    )
}

export default BecomePartnerform

// <!--[if lte IE 8]>
// <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
// <![endif]-->
// <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
// <script>
//   hbspt.forms.create({
// 	portalId: "2339207",
// 	formId: "b67f0c6a-e6e6-4429-8a19-cdc15a2b336f"
// });
// </script>

